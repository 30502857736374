<template>
  <div>
    <!-- Filter BY Name, Email, Phone -->
    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col
              md="4"
            >
              <search
                placeholder="Name, Email, Phone"
                :search-query.sync="search"
                @refresh="refreshTable"
              />
            </b-col>
            <b-col md="4">
              <date-range-filter :date-range.sync="dateRange" />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <b-button
                class="btn-block"
                variant="success"
                @click="exportUsers()"
              >
                Export
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <user-table
      ref="parentTable"
      v-bind="{usersProvider,pagination,tableColumns}"
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import Search from '@/common/components/common/Table/Search.vue'
import UserTable from '@/common/components/MobileUser/UserTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'MobileUsersList',
  components: {
    UserTable,
    Search,
    Pagination,
    SearchButton,
    DateRangeFilter,
  },

  data() {
    return {
      dateRange: [],
      inpoID: this.$store.getters['mainEntity/getEntityId'],
      search: '',
      loading: true,
      tableColumns: [
        { key: 'profile', label: 'Name', formatter: value => `${value?.first_name} ${value?.last_name}` },
        { key: 'email' },
        { key: 'phone_number', label: 'Phone' },
        { key: 'relations', formatter: value => value[0] },
        { key: 'profile.created_at', label: 'registered at', formatter: value => this.$moment(value).format('YYYY-MM-DD') },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    usersProvider() {
      const promise = this.$portalUsers.post(`internalops/get-entity-users?page=${this.pagination.currentPage}`, {
        entity_id: this.inpoID,
        search: this.search,
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
      })
      return promise.then(res => {
        const users = res.data.data

        this.pagination.totalRows = res.data.pagination.total
        return users || []
      }).catch(() => [])
        .finally(() => {
          this.loading = false
        })
    },
    exportUsers() {
      this.$portalUsers.post('internalops/export-entity-users', {}, {
        responseType: 'blob',
        params: {
          entity_id: this.$store.getters['mainEntity/getEntityId'],
          search: this.search,
          page: this.pagination.currentPage,
          start_date: this.dateRange[0],
          end_date: this.dateRange[1],
        },
      }).then(res => {
        const blob = new Blob([res.data])
        const filename = `${this.$moment().format('YYYY-MM-DD')}_users.csv`
        saveAs(blob, filename)
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
</style>
