<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="usersProvider"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      class="position-relative"
      responsive
      show-empty
      empty-text="No users found"
      primary-key="id"
    >
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: viewRoute, params: { id: item.id} }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50"> View </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>

export default {
  props: {
    usersProvider: { type: Function, default: () => null },
    tableColumns: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => {} },
    viewRoute: { type: String, default: 'mobile-users-view' },
  },
  data() {
    return {
      deleteEndPoint: `${process.env.VUE_APP_ENTITIES_BASE_URL}/internalops/user/`,
    }
  },
}
</script>
<style lang='scss' scoped>
</style>
